import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './pages/home/Home';
import BotChat from './pages/chat/BotChat';
import Authentication from './pages/auth/Authentication';

const App = createBrowserRouter([
    { path: '/', element: <Home /> },
    { path: '/chat', element: <BotChat /> },
    { path: '/auth', element: <Authentication /> },
]);

export default <RouterProvider router={App} />;
