import React, { useEffect, useState } from 'react';
import './YouCanAskMeDynamic.scss';

import { useNavigate } from 'react-router-dom';
import UserInputBtn from '../userInputBtn/UserInputBtn';
import { useTranslation } from 'react-i18next';
import { shuffleArray } from '../../utils';
import Grow from '@mui/material/Grow';

export enum YouCanAskMeDynamicOrientation {
    Horizontal,
    Vertical
}

interface Props {
    botId: string
    botName: string
    userInputs: string[]
    orientation: YouCanAskMeDynamicOrientation
}

const YouCanAskMeDynamic: React.FC<Props> = ({ botId, botName, userInputs, orientation }) => {

    const [shuffledInputs, setShuffledInputs] = useState<string[]>([]);
    const [trimmedInputs, setTrimmedInputs] = useState<string[]>([]);
    const [inputsLoad, setInputsLoad] = useState<boolean>(true);
    let animationDelayTime = 0;

    useEffect(() => {
        setShuffledInputs(shuffleArray(userInputs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInputs]);

   useEffect(() => {
        setTrimmedInputs(orientation === YouCanAskMeDynamicOrientation.Vertical ? shuffledInputs.slice(0,3) : shuffledInputs.slice(0,4));
   }, [orientation, shuffledInputs]);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const launchChatHandler = (message: string) => {
        navigate(`/chat?botId=${botId}`, { state: {
            botId,
            botName,
            initMessage: message
        }});
    };

    // const userInputOnChangeHandler = () => {
    //     setInputsLoad(false);
    //     setTimeout(() => {
    //         setShuffledInputs([...shuffleArray(userInputs)]);
    //     }, 100);
    //     setTimeout(() => {
    //         setInputsLoad(true);
    //     }, 300);
    // };

    return (
        <div className='youcanaskmedynamic-container'>
            <div className='youcanaskmedynamic-title-container'>
                {/* <h2 className='youcanaskmedynamic-title'>{t('bot-card.you-can-ask')}</h2> */}
                {/* <button className='youcanaskmedynamic-change-button' onClick={userInputOnChangeHandler}><i className='bi bi-arrow-clockwise'></i>{t('bot-card.user-input-change')}</button> */}
            </div>
            <div className={`youcanaskmedynamic-inputs-container ${orientation === YouCanAskMeDynamicOrientation.Vertical ? 'youcanaskmedynamic-inputs-container-vertical' : 'youcanaskmedynamic-inputs-container-horizontal'}`}>
                {trimmedInputs.map(userInput => {
                    return (
                        <Grow in={inputsLoad} style={{ transformOrigin: '0 0 0' }} {...(inputsLoad ? { timeout: animationDelayTime += 250 } : {})}>
                            <div>
                                <UserInputBtn onClick={()=>launchChatHandler(userInput)}>{userInput}</UserInputBtn>
                            </div>
                        </Grow>
                    );
                })}
            </div>
        </div>
    );
};

export default YouCanAskMeDynamic;