import React from 'react';
import './ShareChannelCard.scss';
import Modal from 'react-bootstrap/Modal';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectURLToBeShared, selectShowShareChannelModal, setShowShareChannelModal } from '../../features/channel/channelSlice';
import { useTranslation } from 'react-i18next';

const ShareChannelCard: React.FC = () => {
    const dispatch = useAppDispatch();
    const urlToBeShared = useAppSelector(selectURLToBeShared);
    const showShareChannelModal = useAppSelector(selectShowShareChannelModal);
    const { t } = useTranslation();

    const handleOnHide = () => {
        dispatch(setShowShareChannelModal({ showShareChannelModal: false, urlToBeShared: '' }));
    };

    const handleCopyLink = () => {
        // Requires secure origin (https)
        navigator.clipboard?.writeText(urlToBeShared || '');
        dispatch(setShowShareChannelModal({ showShareChannelModal: false, urlToBeShared: '' }));
    };

    return (
        <Modal show={showShareChannelModal} onHide={handleOnHide} centered>
            <div className='sharechannelmodal-container'>
                <h3>{t('bot.share-title')}</h3>
                <p>{urlToBeShared}</p>
                <button className='sharechannelmodal-share-btn' onClick={handleCopyLink}>{t('bot.share-btn')}</button>
            </div>
        </Modal>
    );
};

export default ShareChannelCard;