import { StreamChat } from 'stream-chat';

import config from '../config';
const { STREAM_API_KEY } = config;
const client = StreamChat.getInstance(STREAM_API_KEY);

export default class StreamIO {
  static client = client;

  static async streamLogin(id: string, token: string) {
    try {
      const loginResponse = await client.connectUser({ id: id }, token);
      const userId = loginResponse?.me?.id;
      if (!userId) throw new Error('User not created');
      console.log(userId);

      return userId;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  static async streamLogout() {
    try {
      await client.disconnectUser();
      console.log('Logged out');
    } catch (e) {
      throw e;
    }
  }
}