import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

/**
 * Channel stand for stream io channel
 */
export interface ChannelState {
    channels: {
        [key: string]: string
    },
    showShareChannelModal: boolean,
    urlToBeShared?: string
}

export interface ShareChannel {
    urlToBeShared: string,
    showShareChannelModal: boolean
}

const initialState: ChannelState = {
    channels: {},
    showShareChannelModal: false,
    urlToBeShared: ''
};

export const channelSlice = createSlice({
    name: 'channel',
    initialState,
    reducers: {
        setChannel: (state, action: PayloadAction<{ [key: string]: string }>) => {
            state.channels[Object.keys(action.payload)[0]] = Object.values(action.payload)[0];
        },
        clearChannel: (state) => {
            state.channels = {};
        },
        setShowShareChannelModal: (state, action: PayloadAction<ShareChannel>) => {
            state.urlToBeShared = action.payload.urlToBeShared;
            state.showShareChannelModal = action.payload.showShareChannelModal;
        }
    }
});

export const { setChannel, clearChannel, setShowShareChannelModal } = channelSlice.actions;
export const selectChannels = (state: RootState) => state.channel.channels;
export const selectShowShareChannelModal = (state: RootState) => state.channel.showShareChannelModal;
export const selectURLToBeShared = (state: RootState) => state.channel.urlToBeShared;

export default channelSlice.reducer;