import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBots } from '../../services/BotSquareCore';
import { RootState } from '../../app/store';

export interface Bot {
  // 2.0 version
  botId: number;
  botName: string;
  botIcon: string;
  botConfig: BotConfig;
  channelData: ChannelData;
};

 export interface BotsResponse {
    galleryList: Bot[];
    page: number;
 }

export interface fetchError {
  error: string;
}

export interface ChannelData {
  // Need to create stream io channel
  botStreamKey: string;
}

export interface BotConfig {
  description: string;
  enableHistory: boolean;
  historyDatasourceId: number;
  historyCollectionName: string;
  // userInputs: UserInput[];
}

export interface BotState {
    status: 'idle' | 'loading' | 'succeed' | 'failed' | 'endOfList';
    bots: Bot[][],
    page: number,
};

const initialState: BotState = {
  status: 'idle',
    bots: [],
    page: 0, 
};

export const getBotsAsync = createAsyncThunk(
    'bot/getBots',
    async (pageNumber: number): Promise<Bot[]> => {
        try {
            const getBotsResponse = await getBots(pageNumber) as BotsResponse;
            const botList: Bot[] =getBotsResponse.galleryList;
            if (pageNumber !== getBotsResponse.page) {
              throw new Error('Page number mismatch');
            }
            return botList;
        } catch (e) {
            throw e;
        }
    }
);

export const botSlice = createSlice({
    name: 'bot',
    initialState,
    reducers: {
      nextPage: (state) => {
        if (state.status !== 'endOfList') {
          state.page = state.page + 1;
        }
      }
    },
    extraReducers: (builder) => {
        builder
          .addCase(getBotsAsync.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(getBotsAsync.fulfilled, (state, action) => {
            state.status = 'succeed';
            if (action.payload.length === 0) {
              // handle end of list
                state.status = 'endOfList';
                return;
              } else if (action.meta.arg /* request pageNumber */ === state.bots.length) {
                state.bots.push(action.payload);
              } else {
                state.bots[action.meta.arg] = action.payload;
              }
            // maybe handle deduplication here
          })
          .addCase(getBotsAsync.rejected, (state) => {
            state.status = 'failed';
          });
    }
});

export const selectBotsDataStatus = (state: RootState): 'idle' | 'loading' | 'succeed' | 'failed'| 'endOfList' => state.bot.status;
export const selectBots = (state: RootState): Bot[][] => state.bot.bots;
export const selectPage = (state: RootState): number => state.bot.page;
export const { nextPage } = botSlice.actions;
export default botSlice.reducer;