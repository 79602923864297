import React from 'react';
import './ShareBtn.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    onClick?: () => void;
}

const ChatNowBtn: React.FC<Props> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <button onClick={onClick} className='sharebtn'>
            <i className='bi bi-share sharebtn-icon'></i>
            {t('bot-card.share')}
        </button>
    );
};

export default ChatNowBtn;