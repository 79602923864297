import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatNowBtn from '../chatNowBtn/ChatNowBtn';
import ShareBtn from '../shareBtn/ShareBtn';
import Typewriter, { TypewriterClass } from 'typewriter-effect';
import YouCanAskMeDynamic, {YouCanAskMeDynamicOrientation} from '../youCanAskMeDynamic/YouCanAskMeDynamic';
import { setShowShareChannelModal } from '../../features/channel/channelSlice';
import { useAppDispatch } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import './BotPromoCard.scss';

import config from '../../config';
import { BotConfig, ChannelData } from '../../features/bot/botSlice';
import { createChatUrl } from '../../utils/pagesHelper';
const Fade = require('react-reveal/Fade');

interface Props {
  botId: number;
  botName: string;
  botIcon: string;
  botConfig: BotConfig;
  channelData: ChannelData;
  botStreamId: string;
}

const BotPromoCard: React.FC<Props> = ({ botId, botConfig, botIcon, botName, botStreamId}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const typewriterRef = useRef<TypewriterClass | null>(null);
    const description = botConfig.description;
    // imple follow up prop later
    const intro = '';
    const promoLevel = 1;
    const userInput: string[] = [];
    

    const launchChatHandler = () => {
        navigate(`/chat?botId=${botId}&botStreamId=${botStreamId}`, { state: {
            botId,
            botName
        }});
    };

    const handleShareChannel = () => {
        dispatch(setShowShareChannelModal({ showShareChannelModal: true, urlToBeShared:  createChatUrl(botId)}));
    };

    useEffect(() => {
        typewriterRef.current?.deleteAll(10)
            .typeString(intro)
            .start();
    }, [i18n.language, intro]);
    
    return (<Fade bottom duration={900}>
        <div className={`botpromocard-container botpromocard-container-size-${promoLevel}`}>
            <div>
                <div className='botpromocard-profile-container'>
                    <img alt='Bot Profile' src={botIcon} />
                    <div className='botpromocard-name-container'>
                        <h4>{botName}</h4>
                        <p>{description}</p>
                    </div>
                </div>
                {/* TODO: impl after promolevel online */}
                {/* <div className={`botpromocard-content-container ${promoLevel === 3 ? 'botpromocard-content-padding' : ''}`}>
                    {promoLevel === 3 ? 
                        <div className='botpromocard-content-intro'>
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriterRef.current = typewriter;

                                    typewriter.typeString(intro)
                                    .start();
                            }}
                                options={{ delay: 35 }}
                            />
                        </div> : <></>}
                    {promoLevel !== 1 ? 
                        <YouCanAskMeDynamic botId={botId} botName={botName} userInputs={userInput} orientation={promoLevel === 3 ? YouCanAskMeDynamicOrientation.Horizontal : YouCanAskMeDynamicOrientation.Vertical} />
                        :
                        <></>
                    }
                </div> */}
            </div>
            <div className='botpromocard-btn-container'>
                <ShareBtn onClick={handleShareChannel}/>
                <ChatNowBtn onClick={launchChatHandler}/>
            </div>
        </div>
    </Fade>
    );
};

export default BotPromoCard;
