import React from 'react';
import './ChatNowBtn.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    onClick?: () => void;
}

const ChatNowBtn: React.FC<Props> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <button onClick={onClick} className='chatnowbtn'>
            <i className='bi bi-chat-dots chatnowbtn-icon'></i>
            {t('bot-card.chat-now')}
        </button>
    );
};

export default ChatNowBtn;