export enum BSENV {
    prod = 'prod',
    qa = 'qa',
    local = 'local'
}

interface Config {
    BS_CORE_URL: string,
    STREAM_API_KEY: string,
    SHARE_URL_TEMPLATE: string,
    COMPANY_LANDING_URL: string,
    CONTACT_EMAIL: string,
}

// Current ENV
// export let env: BSENV = BSENV.local;
// export let env: BSENV = BSENV.qa;
export let env: BSENV = BSENV.prod;

if (process.env.REACT_APP_STAGE === 'qa') {
    env = BSENV.qa;
} else if (process.env.REACT_APP_STAGE === 'prod') {
    env = BSENV.prod;
} 

const config_local: Config = {
    BS_CORE_URL: 'https://api.botsquare.net',
    STREAM_API_KEY: 'xubfgzjxt635',
    SHARE_URL_TEMPLATE: 'https://bot.botsquare.ai/chat?',
    COMPANY_LANDING_URL: 'https://www.botsquare.ai/',
    CONTACT_EMAIL: 'info@botsquare.ai',
};

const config_qa: Config = {
    BS_CORE_URL: 'https://d21u05om9g7lvp.cloudfront.net',
    // BS_CORE_URL: 'http://ec2-18-117-124-207.us-east-2.compute.amazonaws.com',
    STREAM_API_KEY: 'nth7dgef8bw5',
    // HOST_URL: 'https://d1domwlgv3o98m.cloudfront.net',
    SHARE_URL_TEMPLATE: 'https://d1domwlgv3o98m.cloudfront.net/chat?',
    COMPANY_LANDING_URL: 'https://d1domwlgv3o98m.cloudfront.net/',
    CONTACT_EMAIL: 'info@botsquare.ai',
};

const config_prod: Config = {
    BS_CORE_URL: 'https://api.botsquare.net',
    STREAM_API_KEY: 'xubfgzjxt635',
    // HOST_URL: 'https://bot.botsquare.ai',
    SHARE_URL_TEMPLATE: 'https://bot.botsquare.ai/chat?',
    COMPANY_LANDING_URL: 'https://www.botsquare.ai/',
    CONTACT_EMAIL: 'info@botsquare.ai',
};

let selected_config: Config;
switch (env as BSENV) {
    case BSENV.local:
        selected_config = config_local;
        break; 
    case BSENV.qa:
        selected_config = config_qa;
        break; 
    case BSENV.prod:
        selected_config = config_prod;
        break; 
}

export default selected_config;