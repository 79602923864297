import React, { useState } from 'react';
import './TextField.scss';

interface Props {
    className?: string;
    icon: string;
    type: string;
    input: string;
    placeholder: string;
    hide: Boolean;
    onChange: (arg0: string) => void;
}

const TextField: React.FC<Props> = ({ className, icon, type, input, placeholder, hide, onChange }) => {
    const [hideText, setHideText] = useState(true);

    const handleChange = (event: { target: { value: any; }; }) => {
        onChange(event.target.value);
    };

    return (
        <div className={`textfield-container ${ className }`}>
            <div className='textfield-content'>
                <div className='textfield-icon'>
                    <i className={ icon }/>

                    { hide &&
                        <div className='hide-container'>
                            { hideText ?
                                <i className='bi bi-eye-slash hide-icon' onClick={ () => setHideText(false) }/> :
                                <i className='bi bi-eye hide-icon' onClick={ () => setHideText(true) }/>
                            }
                        </div>
                    }

                    <input className={`input-field ${icon !== '' ? 'with-icon' : ''}`} type={ hideText ? type : 'standard' } value={ input } placeholder={ placeholder } onChange={ handleChange } />
                </div>
            </div>
        </div>
    );
};

export default TextField;