import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './Authentication.scss';

import TextField from '../../components/textfield/TextField';
import BSLogoWhite from '../../components/bsLogo/BSLogoWhite';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import AuthService from '../../services/AuthService';
import ConfirmationForm from '../../components/dialog/ConfirmationForm';
import ForgotPasswordForm from '../../components/dialog/ForgotPasswordForm';
import { LoadingButton } from '@mui/lab';
import {styled} from '@mui/material';
import { createEndUser, fetchEndUser } from '../../services/BotSquareCore';

// import config from '../../config';

const StyledLoadingButtom = styled(LoadingButton)(({theme}) => ({
    '&.MuiLoadingButton-root': {
        backgroundColor: '#3849D3',
        color: 'white',
        borderRadius: '8px',
        padding: '12px 24px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    '&.MuiLoadingButton-root.Mui-disabled': {
        backgroundColor: '#F2F5FF',
        color: '#F2F5FF',
    },

}));

const Authentication: React.FC = () => {
    const navigate = useNavigate();
    // const dispatch = useAppDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [check, setCheck] = useState('');
    const [isSignIn, setIsSignIn] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [showFPForm, setShowFPForm] = useState(false);
    const [agreementChecked, setAgreementChecked] = useState(false);
    const [redirect, setRedirect] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordTooShort, setPasswordTooShort] = useState(false);
    const [searchParams] = useSearchParams();

    const validatePassword = (password: string) => {
        let strength = 'weak';
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasNonalphas = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=]/.test(password);
        const hasSpaces = /\s/.test(password);

        if (password.length >= 8 && hasUppercase && hasLowercase && hasNumbers && hasNonalphas && !hasSpaces) {
            strength = 'strong';
        } else if (password.length >= 8 && hasUppercase && hasLowercase && hasNumbers) {
            strength = 'medium';
        }
        setPasswordStrength(strength);
    };

    const handleChangePassword = (password: string) => {
        setPassword(password);
        if (password.length < 8) {
            setPasswordTooShort(true);
        } else {
            setPasswordTooShort(false);
            validatePassword(password);
        }
    };

    useEffect(() => {
        // Retrive redirect link
        searchParams.forEach((value, key) => {
            if (key === 'redirect') {
                setRedirect(value);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then( result => {
                if (result) {
                    console.log('authenticated');
                    redirectToSite();
                } else {
                    console.log('not authenticated');
                }
            })
            .catch( error => {
                console.log(error);
            });
    },[navigate]);

    const signin = async () => {
        try {
            setIsLoading(true);
            const { token } = await AuthService.signIn(email, password);
            console.log(token);
            setIsLoading(false);
            console.log('signin success');
        } catch (error) {
            if (error instanceof Error && error.name === 'UserNotConfirmedException') {
                await Auth.resendSignUp(email);
                setShowDialog(true);
            }
            setLoginError((error as Error).message);
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            const data = await fetchEndUser();
            console.log(data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            const { data } = await createEndUser();
            console.log(data);
            setIsLoading(false);
        }

        redirectToSite();
    };

    const signup = async () => {
        try {
            setIsLoading(true);
            const result = await AuthService.sendCode(email, password, name);
            console.log(result);
            setShowDialog(true);
            setIsLoading(false);
        } catch (error) {
            const errorCode = (error as Error).message;
            setErrorMessage(errorCode);
            console.log(errorCode);
            setIsLoading(false);
        }
    };

    const openInNewTab = (url: string) => {
        window.open(url);
    };

    const redirectToSite = () => {
        if(redirect === '') {
            navigate('/');
            return;
        }
        navigate(redirect);
    };

    return (
        <div className='auth-container'>
            {/* <div className='auth-container-bg' /> */}
            {/* <div className='back-button' onClick={ () => redirectToSite() }>&lt; {('Back')}</div> */}

            <div className='auth-content'>
                <div className='auth-content-container'>
                    {isSignIn ?
                        <div className='auth-form rounded text-main'>
                            <h1>{('User Space')}</h1>
                            <p className='auth-form-no-dev-notice'>Heads up! Developer accounts can't be used to log into this portal</p>

                            <div className='auth-text'>
                                <label>{('Need a new account?')}&nbsp;</label>
                                <label className='auth-inline-button' onClick={() => setIsSignIn(false)}>{('Create an account')}</label>
                            </div>

                            {loginError !== '' &&
                                <div className='auth-inline-error auth-text'>{loginError}</div>
                            }
                            <TextField icon='bi bi-envelope icon' type='email' input={email} placeholder={('Email address')} hide={false} onChange={setEmail} />

                            <TextField icon='bi bi-lock icon' type='password' input={password} placeholder={('Password')} hide={true} onChange={setPassword} />

                            <div className='forgot-password auth-text'>
                                <label className='auth-inline-button' onClick={() => setShowFPForm(true)}>{('Forgot Password?')}</label>
                            </div>

                            <div className='sign-in-button enable' onClick={signin}>{('Sign in')}</div>

                            {/* <Divider>{t('auth.or')}</Divider> */}

                            {/* <AppleBtn /> */}

                            {/* <GoogleBtn /> */}
                        </div> :
                        <div className='auth-form'>
                            <h1>{('Create an account')}</h1>
                            <div>
                                <label>{('Already have an account?')}&nbsp;</label>
                                <label className='auth-inline-button auth-text' onClick={() => setIsSignIn(true)}>{('Sign in')}</label>
                            </div>
                            {errorMessage !== '' &&
                                <div className='auth-inline-error auth-text'>{errorMessage}</div>
                            }

                            <div className='text-main form-title p-1'>Name</div>
                            <TextField
                                icon=''
                                type='name' input={name} placeholder='Name' hide={false} onChange={setName} />
                            <div className='text-main form-title p-1'>Email</div>
                            <TextField
                                icon=''
                                type='email' input={email} placeholder={('Email address')} hide={false} onChange={setEmail} />
                            <div className='text-main form-title p-1'>Password</div>
                            <TextField
                                icon=''
                                type={'password'}
                                input={password}
                                placeholder={('Password')}
                                hide={true}
                                onChange={handleChangePassword}
                            />
                            {passwordTooShort ?
                                <div className='password-error pb-3 text-danger'>Password is too short, at least 8 characters</div> :
                                passwordStrength &&
                                <div className={`row align-items-center password-strength pb-3 text-main`}>
                                    <div className='col-6'>Password Strength: <span className={`${passwordStrength}`}>{passwordStrength.charAt(0).toUpperCase() + passwordStrength.slice(1)}</span></div>
                                    <div className='col-5 strength-bars'>
                                        <div className={`strength-bar ${passwordStrength === 'weak' || passwordStrength === 'medium' || passwordStrength === 'strong' ? passwordStrength : ''}`}></div>
                                        <div className={`strength-bar mx-1 ${passwordStrength === 'medium' || passwordStrength === 'strong' ? passwordStrength : ''}`}></div>
                                        <div className={`strength-bar ${passwordStrength === 'strong' ? passwordStrength : ''}`}></div>
                                    </div>
                                    <div className='col-1'>
                                        <OverlayTrigger
                                            key='right'
                                            placement='right'
                                            overlay={
                                                <div className='tooltips p-4 m-4'>
                                                    <div className='card mb-3'>
                                                        <div className='card-header bg-main text-white'>Password security tips</div>
                                                        <div className='card-body mx-4'>
                                                            <p className={password.length >= 8 ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>At least 8 characters</p>
                                                            <p className={/[A-Z]/.test(password) ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>Uppercase letter</p>
                                                            <p className={/[a-z]/.test(password) ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>Lowercase letter</p>
                                                            <p className={/\d/.test(password) ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>Numbers</p>
                                                            <p className={/[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-=]/.test(password) ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>Symbol (@,#,$...)</p>
                                                            <p className={!/\s/.test(password) ? 'text-success' : 'text-disabled'}><i className='bi bi-check-circle pe-2'></i>No space</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <i className='bi bi-info-circle-fill'></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            }
                            <div className='text-main form-title p-1'>Confirm Password</div>
                            <TextField
                                icon=''
                                type='password'
                                input={check}
                                placeholder={('Confirm Password')}
                                hide={true}
                                onChange={setCheck}
                            />
                            {password !== '' &&
                                (
                                    password !== check ?
                                        <div className='text-danger'>Passwords do not match</div> :
                                        <div className='text-success'>Passwords match</div>
                                )
                            }
                            <div className='auth-agreement'>
                                {agreementChecked ?
                                    <div className='auth-checkbox-content' onClick={() => setAgreementChecked(false)}>
                                        <i className='bi bi-check-lg auth-checkmark'></i>
                                        <div className='auth-checkbox-checked' />
                                    </div> :
                                    <div className='auth-checkbox' onClick={() => setAgreementChecked(true)} />
                                }
                                <label>{('I agree to')}&nbsp;</label>
                                <label className='auth-inline-button' onClick={() => openInNewTab('https://www.botsquare.ai/privacypolicy')}>{('Privacy Policy')}</label>
                                <label>&nbsp;{('and')}&nbsp;</label>
                                <label className='auth-inline-button' onClick={() => openInNewTab('https://www.botsquare.ai/termsandconditions')}>{('Terms of Condition')}</label>
                            </div>

                            <StyledLoadingButtom
                                loading={isLoading}
                                loadingIndicator='Loading…'
                                onClick={signup}>
                                {('Create an account')}
                            </StyledLoadingButtom>

                        </div>
                    }
                </div>
                <ConfirmationForm isOpen={showDialog} email={email} password={password} onClose={() => setShowDialog(false)} />
                <ForgotPasswordForm isOpen={showFPForm} onClose={() => setShowFPForm(false)} />
            </div>

            {/* <Footnote /> */}
        </div>
    );
};

export default Authentication;