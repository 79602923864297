import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import './BotChat.scss';
import { setChannel, selectChannels } from '../../features/channel/channelSlice';
import CustomChannelHeader from '../../components/bsChannelHeader/BSChannelHeader';
import ShareChannelCard from '../../components/shareChannelCard/ShareChannelCard';
import { Bot, getBotsAsync, selectBots, selectBotsDataStatus } from '../../features/bot/botSlice';
import YouCanAskMeDynamic, {YouCanAskMeDynamicOrientation} from '../../components/youCanAskMeDynamic/YouCanAskMeDynamic';

import { createChannel, getBotByBotId, getStreamIoToken } from '../../services/BotSquareCore';

import config from '../../config';
import AuthService from '../../services/AuthService';
import { getChatBotsAsync, selectChatBot, selectChatBotStatus } from '../../features/bot/chatBotSlice';
const { STREAM_API_KEY } = config;
const client = StreamChat.getInstance(STREAM_API_KEY);

const BotChat: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isClientReady, setIsClientReady] = useState(false);
    const dispatch = useAppDispatch();
    const channels = useAppSelector(selectChannels);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [botId, setBotId] = useState('');
    const location = useLocation();
    const chatBot = useAppSelector(selectChatBot);
    const chatBotDataStatus = useAppSelector(selectChatBotStatus);
    const [botName, setBotName] = useState<string>('');
    const [userInput, setUserInput] = useState<string[]>([]);

    // Init chat
    useEffect(() => {
        // Retrive bot id
        let hasBotId = false;
        searchParams.forEach((value, key) => {
            if (key === 'botId') {
                setBotId(value);
                hasBotId = true;
        if (chatBotDataStatus === 'idle' || chatBotDataStatus === 'failed') dispatch(getChatBotsAsync(value)); 
            }
        });
        if (!hasBotId) {
            console.log('BotId not set, going home: ');
            navigate('/');
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    // When all data ready: botId, botsData
    // When users enter from home page, chatBotDataStatus is 'succeeded'
    useEffect(() => {
        // Data incomplete
        if ( !botId || !chatBot) return;
        connectStreamIoUser();
        
        const sendMessageToChannel = (channelId: string, message: string) => {
            client.channel('messaging', channelId).sendMessage({text: message});
            location.state.initMessage = undefined;
        };

        // Channel exist
        if (channels[botId]) {
            console.log('Reloading channel: ', channels[botId]);

            // Send init message
            if (location?.state?.initMessage) sendMessageToChannel(channels[botId], location.state.initMessage);
        } else {
            // Create new channel
            createChannel(botId).then((response) => {
                
                const channelId = response?.channel?.id;
                dispatch(setChannel({ [botId]: channelId }));
                console.log('Channel created: ', channelId);

                // Send init message
                if (location?.state?.initMessage) sendMessageToChannel(channelId, location.state.initMessage);
            });
        }

        setIsLoading(false);
    }, [botId, chatBot, channels, dispatch, location.state]);

    // When user access from shared link, not bots info available
    useEffect(() => {
        if (!botId) return;
        createChannel(botId).then((response) => {
            const channelId = response?.channel?.id;
            dispatch(setChannel({ [botId]: channelId }));
            console.log('Channel created: ', channelId);
            setIsLoading(false);
        });
    }, [botId]);

    // Bots data ready, set bot name and user inputs
    useEffect(() => {
        if (chatBotDataStatus !== 'succeed' || !botId || !(chatBot?.botId)) return;

            if (chatBot.botId.toString() === botId) {
                setBotName(chatBot.botName);
                setUserInput(JSON.parse(JSON.stringify([])));
            }
    }, [chatBotDataStatus, botId, chatBot]);
    
    const connectStreamIoUser = async () => {
        try {
            if (isClientReady) {
                // console.log('Client already connected');
                return;
            }
            setIsLoading(true);
            // TODO send to request same time use promise graph
            const userStreamId = await AuthService.getEndUser()
            .then((data) => {
                return data?.info.enduserStreamKey;
            })
                .catch(error => {
                    console.log(error);
                    // send users to login page if no end user found
                    navigateToAuth();
                });

            const streamIoToken = await getStreamIoToken().then(({data}) => {
                return data.token;
            }).catch((error) => {
                console.log(error);
                    // send users to login page if no Stream io token found
                    navigateToAuth();
            });

            await client.connectUser({ id: userStreamId? userStreamId : '' }, streamIoToken);
            setIsLoading(false);
            setIsClientReady(true);
        } catch (e) {
            console.error('Error occured while init preview channel', e);
            // send users to login page if client connect failed
            navigateToAuth();
        }
    };

    const navigateToAuth = async () => {
        if (botId!) {
            navigate('/auth');
        }
        navigate('/auth?redirect=/chat?botId='+ botId);
    };

    return (
        <div className='botchat-container'>
            {(isLoading || !isClientReady) ?
                <div className='bitchat-spinner-container'>
                    <Spinner animation='border' variant='secondary' />
                </div> :
                <div className='botchat-and-history-chat'>
                    <div className='botchat-chat-container'>
                        <Chat client={client}>
                            <Channel channel={client.channel('messaging', channels[botId])}>
                                <Window >
                                    <CustomChannelHeader title={botName} botId={parseInt(botId)} />
                                    <MessageList />
                                    <div className='botchat-youcanaskmedynamic-container'>
                                        <YouCanAskMeDynamic botId={botId} botName={botName} userInputs={userInput} orientation={YouCanAskMeDynamicOrientation.Horizontal} />
                                    </div>
                                    <MessageInput grow={true} disableMentions={true} />
                                </Window>
                                <Thread />
                            </Channel>
                        </Chat>
                    </div>
                    {/* <HistoryCard /> */}
                </div>
            }
            <ShareChannelCard />

        </div>
    );
};

export default BotChat;