import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBotByBotId } from '../../services/BotSquareCore';
import { RootState } from '../../app/store';


export interface ChatBotState {
    status: 'idle' | 'loading' | 'succeed' | 'failed';
    bot: any,
};

const initialState: ChatBotState = {
  status: 'idle',
    bot: undefined,
};

export const getChatBotsAsync = createAsyncThunk(
    'bot/getBots',
    // Add a return type for the thunk props!
    async (botId: string): Promise<any> => {
        try {
            const getBotResponse = await getBotByBotId(botId);
            return getBotResponse;
        } catch (e) {
            throw e;
        }
    }
);


export const chatBotSlice = createSlice({
    name: 'bot',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
          .addCase(getChatBotsAsync.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(getChatBotsAsync.fulfilled, (state, action) => {
            state.status = 'succeed';
            state.bot = action.payload;
          })
          .addCase(getChatBotsAsync.rejected, (state) => {
            state.status = 'failed';
          });
    }
});

export const selectChatBot = (state: RootState): any => state.bot;
export const selectChatBotStatus = (state: RootState): string => state.bot.status;

export default chatBotSlice.reducer;