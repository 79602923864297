import './BSChannelHeader.scss';
import { useNavigate } from 'react-router-dom';
import { setShowShareChannelModal } from '../../features/channel/channelSlice';
import { useAppDispatch } from '../../app/hooks';
import { createChatUrl } from '../../utils/pagesHelper';

interface Props {
    title: string,
    botId: number,
} 

const BSChannelHeader: React.FC<Props> = ({ title, botId}) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const haneleShareChannel = () => {
        dispatch(setShowShareChannelModal({ showShareChannelModal: true, urlToBeShared: createChatUrl(botId)}));
    };

    return (
        <div className='bscheader-container'>
            <i className='bi bi-arrow-left bscheader-btn' onClick={() => navigate('/')} />
            {title}
            <i className='bi bi-box-arrow-up bscheader-btn' onClick={haneleShareChannel} />
        </div>
    );
};

export default BSChannelHeader;