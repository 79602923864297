import { Auth as AmplifyAuth } from 'aws-amplify';
import { createEndUser, fetchEndUser } from './BotSquareCore';
import StreamIO from '../modules/streamio.module';

interface EndUser {
    id: string;
    name: string;
    email: string;
    origin: string;
    identityKey: string;
    info: {
        imageUrl: string;
        enduserStreamKey: string;
    };
    created_at: string;
    updated_at: string;
    deleted_at: string;
}

//TODO convert to a static method
class AuthService {
    private endUser: EndUser | undefined;
    async sendCode(email: string, password: string, name: string) {
        try {
            const { user } = await AmplifyAuth.signUp({
                username: email,
                password,
                attributes: {
                    email,
                    name: name,
                },
                autoSignIn: { // optional - enables auto sign in after user is confirmed
                    enabled: false,
                }
            });
            console.log(user);
        } catch (error) {
            console.error('Error signing up: ', error);
            throw error;
        }
    }

    async confirmSignUp(email: string, password: string, code: string) {
        try {
            const result = await AmplifyAuth.confirmSignUp(email, code, { forceAliasCreation: false });
            console.log(result);
            const { uid, token } = await this.signIn(email, password);
            console.log(uid, token);
            const { data } = await createEndUser();
            // Note: Rwu modify for bot gallery
            // token not using in later code
            // Feel free to change it later
            return {data};
        } catch (error) {
            console.error('Error confirming sign up: ', error);
            throw error;
        }
    }

    async signIn(email: string, password: string) {
        try {
            const credential = await AmplifyAuth.signIn(email, password);
            const token = credential.getSignInUserSession().accessToken.jwtToken;
            const uid = credential.attributes.sub;
            return { uid, token };
        } catch (error) {
            console.error('Error signing in: ', error);
            throw error;
        }
    }

    async getEndUser() {
        try {
            if (!this.endUser) {
            this.endUser = await fetchEndUser();
            }
            return this.endUser;
        } catch (error) {
            console.error('Error getting end user: ', error);
            throw error;
        }
    }
            

    async getCurrentUser() {
        try {
            const user = await AmplifyAuth.currentAuthenticatedUser();
            return user;
        } catch (error) {
            console.error('Error getting current user: ', error);
            throw error;
        }
    }

    async getCurrentUserTokenAndId() {
        try {
            const user = await this.getCurrentUser();
            const session = await AmplifyAuth.currentSession();
    
            const userId = user.username;
            const idToken = session.getIdToken().getJwtToken();
            console.log('idToken', idToken);
    
            return { userId, idToken };
        } catch (error) {
            console.error('Error getting user token and id: ', error);
            throw error;
        }
    }

    async signOut() {
        try {
            await AmplifyAuth.signOut();
            await StreamIO.streamLogout();
            console.log('User has been signed out');
        } catch (error) {
            console.error('Error signing out user: ', error);
        }
    }

    async forgotPassword(email: string) {
        try {
            const data = await AmplifyAuth.forgotPassword(email);
            console.log(data);
            return data;
        } catch (error) {
            console.error('Error sending forgot password', error);
            throw error;
        }
    }

    async forgotPasswordConfirm(email: string, code: string, newPassword: string) {
        try {
            const data = await AmplifyAuth.forgotPasswordSubmit(email, code, newPassword);
            console.log(data);
            return data;
        } catch (error) {
            console.error('Error finishing forgot password flow', error);
            throw error;
        }
    }
}

export default new AuthService();