import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { useTranslation } from 'react-i18next';
import BotPromoCard from '../../components/botPromoCard/BotPromoCard';
import { Bot } from '../../features/bot/botSlice';
import { useNavigate} from 'react-router-dom';
import { Auth } from 'aws-amplify';

export interface windowDimension {
    width: number;
    height: number;
}

interface Prop {
    data: Bot[][];
}

const BotGrid: React.FC<Prop> = ({ data }): JSX.Element => {
    const getCurrentDimension = (): windowDimension => {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    };

    const currData: Bot[] = /*i18n.language === 'zh-CN' ? zhData : enData*/ JSON.parse(JSON.stringify(data)).flat();
    const [screenSize, setScreenSize] = useState<windowDimension>(getCurrentDimension());
    const navigate = useNavigate();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    }, [navigate]);
    
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension());
        };
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        });
    }, [screenSize]);

    const l1Bots: JSX.Element[] = [];
    const botList: Bot[] = [];
    console.log(currData);

    // Fill botList
    currData.forEach((data: Bot) => {
            botList.push(data);
    });

    // Fill l1Bots
        while (botList.length > 0) {
            const bot = botList.shift()!;
            if (!bot) {
                // when user click back there are all list shows null
                break;
            }
            l1Bots.push(
                <Col lg={4}>
                    <BotPromoCard 
                        botId={bot.botId}
                        botStreamId={bot.channelData.botStreamKey}
                        botIcon={bot.botIcon}
                        botName={bot.botName}
                        botConfig={bot.botConfig}
                        channelData={bot.channelData}
                    />
                </Col>
            );
        }

    // Generate rows
    const rows: JSX.Element[][] = [];
    while (l1Bots.length !== 0) {
        const row: JSX.Element[] = [];
        
        // Row with l1
        if (l1Bots.length > 0) {
            if (l1Bots.length >= 3) {
                row.unshift(l1Bots.shift()!);
                row.unshift(l1Bots.shift()!);
                row.unshift(l1Bots.shift()!);
            } else {
                while (l1Bots.length > 0) {
                    row.unshift(l1Bots.shift()!);
                }
            }

            rows.push(row);
            continue;
        }
    }

    return (<>{
        rows.map(row => {
            return <Row>
                {row.map(e => e)}
            </Row>;
        })
    }</>);
};

export default BotGrid;
