import { useState } from 'react';
import ReactModal from 'react-modal';
import TextField from '../textfield/TextField';
import './ConfirmationForm.scss';
import { useTranslation } from 'react-i18next';
import AuthService from '../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';

interface Props {
    isOpen: boolean;
    email?: string;
    password?: string;
    onClose?: () => void;
}

const Dialog: React.FC<Props> = ({ isOpen, email, password, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [input, setInput] = useState('');
    const [showLoading, setShowLoading] = useState(false);

    const isEnable = () => {
        return email !== undefined && password !== undefined && input.length === 6;
    };

    const handleClose = () => {
        setInput('');
        onClose?.();
    };

    const confirmCode = async () => {
        if (email !== undefined && password !== undefined && input.length === 6) {
            setShowLoading(true);
            console.log('start confirm code');
            const data = await AuthService.confirmSignUp(email, password, input);
            console.log(data);
            setShowLoading(false);
            onClose?.();
            navigate('/');
        }
    };
    
    return (
        <>
            <ReactModal className='reset-container' overlayClassName='reset-overlay' isOpen={ isOpen } onRequestClose={ handleClose }>
                <div className='reset-back-container'>
                    <i className='bi bi-x-lg reset-back' onClick={ handleClose }></i>
                </div>
                <div className='reset-form'>
                    <h1>Confirm Security Code</h1>

                    <label className='auth-text'>{t('auth.resetPasswordMessage')}</label>

                    <TextField icon='bi bi-lock icon' type='normal' input={ input } placeholder='Security Code' hide={false} onChange={ setInput } />
                    
                    <div className={`form-button ${ isEnable() ? 'enable' : 'disable'}`} onClick={ confirmCode }>{t('auth.send')}</div>
                </div>
            </ReactModal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showLoading}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </>
    );
};

export default Dialog;
