import React from 'react';
import './homepageFootnote.scss';
import { useTranslation } from 'react-i18next';
import BSLogo, { BSLogoColorMode } from '../bsLogo/BSLogo';

import config from '../../config';
const CONTACT_EMAIL = config.CONTACT_EMAIL;
const COMPANY_LANDING_URL = config.COMPANY_LANDING_URL;

const HomepageFootnote: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className='homepage-footnote'>
            <div className='container homepage-footnote-container'>
                <div className='container homepage-footnote-icon-bar'>
                    <a href={COMPANY_LANDING_URL}>
                        <BSLogo colorMode={BSLogoColorMode.White} className='footnote-logo'/>
                    </a>
                    <a href='https://www.producthunt.com/posts/botsquare?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-botsquare' target='_blank' rel='noreferrer'><img className='footnote-product-hunt' src='https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=394147&theme=neutral' alt='BotSquare - An&#0032;AI&#0032;app&#0032;store&#0032;brings&#0032;developers&#0032;and&#0032;users&#0032;to&#0032;one&#0032;place | Product Hunt' /></a>
                </div>
                <div className='container homepage-footnote-texts-container'> 
                    <div><p className='homepage-footnote-email'>{t('Contact us: ')}<a className='homepage-footnote-email' href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a></p></div>
                    <p className='homepage-footnote-copyright'>©2023 by BotSquare.ai</p>
                    <div><p className='homepage-footnote-terms'><a className='homepage-footnote-terms' href='https://www.botsquare.ai/privacypolicy'>{'Privacy Policy'}</a><span> | </span><a className='homepage-footnote-terms' href='https://www.botsquare.ai/termsandconditions'>{'Terms and Conditions'}</a></p></div>
                </div>
            </div>
        </div>
    );
};

export default HomepageFootnote;