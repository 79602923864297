import axios from 'axios';

import config from '../config';
import AuthService from './AuthService';
const BOT_SQAURE_CORE_URL = `${config.BS_CORE_URL}`;

const setupConfig = async () => {
  try {
    const token = await AuthService.getCurrentUserTokenAndId();
    const config = {
      headers: { Authorization: `Bearer ${token.idToken}` }
    };
    return config;
  } catch (error) {
    console.error(error);
  }
};

// change createchannel connect to localservice other port
export const createChannel = async (botId: string) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.post(
      `${BOT_SQAURE_CORE_URL}/api/v1/stream/enduser/channel`,
      { botId },
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getBots = async (page: number) => {
  try {
    const config = await setupConfig();
    // please add query params for pagination
    const { data } = await axios.get(
      `${BOT_SQAURE_CORE_URL}/api/v1/gallery?page=${page}`,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const createEndUser = async (imageUrl?: string) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.post(
      `${BOT_SQAURE_CORE_URL}/api/v1/enduser`,
      {imageUrl}
      ,config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

// use bot id to get bot
export const getBotByBotId = async (botId: string) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.get(
      `${BOT_SQAURE_CORE_URL}/api/v1/gallery/bot/${botId}`,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchEndUser = async () => {
  try {
    const config = await setupConfig();
    const { data } = await axios.get(
      `${BOT_SQAURE_CORE_URL}/api/v1/enduser`,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getStreamIoToken = async () => {
  try {
    const config = await setupConfig();
    const { data } = await axios.get(
      `${BOT_SQAURE_CORE_URL}/api/v1/stream/enduser/token`,
      config
    );
    return { data };
  } catch (error) {
    throw error;
  }
};
// TODO create a type for request after verifying the request body
// {
//   "info": {
//     "additionalProp1": "string",
//     "additionalProp2": "string",
//     "additionalProp3": "string"
//   },
//   "name": "string"
// }
export const modifyEndUser = async (request: any) => {
  try {
    const config = await setupConfig();
    const { data } = await axios.put(
      `${BOT_SQAURE_CORE_URL}/api/v1/enduser`,
      {request},
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteEndUser = async () => {
  try {
    const config = await setupConfig();
    const { data } = await axios.put(
      `${BOT_SQAURE_CORE_URL}/api/v1/enduser`,
      config
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export type MemberObject = {
  id: number
  email: string
  name: string
  identityKey: string
  orgin: string
  info: {
    imageUrl: string
  }
};