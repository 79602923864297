import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { getBotsAsync, selectBotsDataStatus, selectBots, nextPage, selectPage } from '../../features/bot/botSlice';
import Nav from '../../components/navbar/BSNav';
import HomepageFootnote from '../../components/homepageFootnote/homepageFootnote';
import { useTranslation } from 'react-i18next';
import ShareChannelCard from '../../components/shareChannelCard/ShareChannelCard';
import BotGrid from './BotGrid';

import './Home.scss';

import { Auth } from 'aws-amplify';

interface windowDimension {
    width: number;
    height: number;
}

const Home: React.FC = () => {
    const getCurrentDimension = (): windowDimension => {
        return {  
            width: window.innerWidth,
            height: window.innerHeight
        };
    };

    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMoreBot, setIsLoadingMoreBot] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const bots = useAppSelector(selectBots);
    const pageNumber = useAppSelector(selectPage);
    const botsDataStatus = useAppSelector(selectBotsDataStatus);
    const { t } = useTranslation();
    const [showLoadingMoreBotSpinner, setShowLoadingMoreBotSpinner] = useState(false);
    const [screenSize, setScreenSize] = useState<windowDimension>(getCurrentDimension());
    const navigate = useNavigate();

    useEffect(() => {
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init = async () => {
        // Get bots
        if (botsDataStatus !== 'succeed') {
            dispatch(getBotsAsync(pageNumber));
        }
    };

    async function PageNumberIncrease() {
        try {
            setIsLoadingMoreBot(true);

            await dispatch(nextPage());
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoadingMoreBot(false);
        }
    }
    
    function handleScroll() {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isLoading) return;
        PageNumberIncrease();
    }

    useEffect(() => {
        if (hasMore && !isLoading && !isLoadingMoreBot) {
            window.addEventListener('scroll', handleScroll);
        }

        return(() => {
            window.removeEventListener('scroll', handleScroll);
        });

    }, [hasMore, isLoading]);

useEffect(() => {
    try{
     dispatch(getBotsAsync(pageNumber));
    }
    catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
        setIsLoadingMoreBot(false);
    }
}, [dispatch, pageNumber]);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .catch(error => {
                console.log(error);
                navigate('/auth');
            });
    }, [navigate]);
    

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension());
            console.log('screenSize', screenSize);
        };
        window.addEventListener('resize', updateDimension);
        
        return(() => {
            window.removeEventListener('resize', updateDimension);
        });
    }, [screenSize]);

    useEffect(() => {
        if (botsDataStatus === 'succeed') {
            setIsLoading(false);
            setIsLoadingMoreBot(false);
        }
        else if (botsDataStatus === 'endOfList') {
            setIsLoading(false);
            setIsLoadingMoreBot(false);
            setHasMore(false);
        }
    }, [botsDataStatus]);
    useEffect(() => {
        let timeout: any;

        if (isLoadingMoreBot) {
            setShowLoadingMoreBotSpinner(true);
        } else {
            // Set a timeout to hide the spinner after 1 seconds
            timeout = setTimeout(() => {
                setShowLoadingMoreBotSpinner(false);
            }, 1000);
        }

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isLoadingMoreBot]);

    return (
        <div className='home-container'>
            <div className='home-content-container'>
                <Nav />
                {isLoading ? <div className='home-spinner-container'><Spinner animation='border' variant='secondary' /></div> : 
                <div className='container'>
                    <h2 className='home-content-title'>{t('bots.slogan')}</h2>
                    {/* TODO: re-enable apple app ads  */}
                    {/* <h4 className='home-content-download-text'>{t('bots.download-ios')}<a className='home-content-download-ios-button' href='https://apps.apple.com/us/app/botsquare/id6448202238?itsct=apps_box_badge&amp;itscg=30200'><img src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1683417600' alt='Download on the App Store'/></a></h4> */}
                    <BotGrid data={bots!} />
                    {/* <AppStoreBar /> */}
                    {showLoadingMoreBotSpinner && <div className='home-spinner-container'><Spinner animation='border' variant='secondary'/></div>}
                </div>
                }
                {/* <div onClick={ () => console.log('current user', auth.currentUser)}>Text button</div> */}
            </div>
            <HomepageFootnote />
            <ShareChannelCard />
        </div>
    );
};

export default Home;
