import React from 'react';
import './UserInputBtn.scss';

interface Props {
    children?: React.ReactNode;
    onClick?: () => void;
    className?: string;
}

const UserInputBtn: React.FC<Props> = ({ onClick, children, className }) => {
    return (
        <button onClick={onClick} className={`user-input-btn ${className}`}>
            {children}
        </button>
    );
};

export default UserInputBtn;